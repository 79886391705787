// extracted by mini-css-extract-plugin
export var buttonContainer = "TextAndPhotoMasthead-module--button-container--d0399";
export var masthead = "TextAndPhotoMasthead-module--masthead--b88c6";
export var mastheadInner = "TextAndPhotoMasthead-module--masthead-inner--685eb";
export var photo = "TextAndPhotoMasthead-module--photo--609c1";
export var photoContainer = "TextAndPhotoMasthead-module--photo-container--0e3b2";
export var primary = "TextAndPhotoMasthead-module--primary--fbe55";
export var primaryHeading = "TextAndPhotoMasthead-module--primary-heading--e4cae";
export var secondaryHeading = "TextAndPhotoMasthead-module--secondaryHeading--51d6a";
export var textContent = "TextAndPhotoMasthead-module--text-content--2804f";
export var textTags = "TextAndPhotoMasthead-module--text-tags--ce1e7";