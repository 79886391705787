import React from 'react';
import * as styles from '../pagebuilderComponents/TextAndPhotoMasthead/TextAndPhotoMasthead.module.scss'
import * as stylep from './PodcastMain.module.scss'
import PodcastImage from '../../images/podcast-main.svg'
import PodcastArchive from '../PodcastArchive/PodcastArchive';
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import {StaticQuery, graphql} from 'gatsby'

export default function PodcastMain ({ data }) {
    var topSocials={justifyContent:"flex-start"}
    return (<>
        <StaticQuery
                query={graphql`
                    query podcasQuery {
                     sanitySiteSettings {
                        podcastHeader
                        podcastSubheader
                        podcastDescription
                        podcastLeftHeader
                        podcastLeftDescription
                        podcastLeftLink
                        podcastRightHeader
                        podcastRightDescription
                        }
                    }   
                `}
                render={data => ( <section className={stylep.podcastMainWrapper} >
                
                <div className={styles.masthead}>
                    <div className="wrapper">
                        <div className={styles.mastheadInner}>
                            <div className={styles.primary}>
                                    <h1 className={styles.primaryHeading}>{data.sanitySiteSettings.podcastHeader}</h1>
                               
                                    <div className={styles.secondaryHeading}>
                                        {data.sanitySiteSettings.podcastSubheader}
                                    </div>
                                            
                                    <div className={styles.textContent}>
                                        {data.sanitySiteSettings.podcastDescription}
                                    </div>
                            
                                    <div className={styles.buttonContainer}>
                                        <div className={stylep.podcastLinksWrap} style={topSocials}>
                                            <a href="https://open.spotify.com/show/1uXLjKEj5ydJdQQ4uq6M3H"><span><StaticImage src="../../images/podcasts/spotify-logo.jpg" alt="Spotify"/></span></a>
                                            <a href="https://podcasts.apple.com/us/podcast/thinspace/id1527605777?uo=4https://podcasts.apple.com/us/podcast/thinspace/id1527605777?uo=4"><span><StaticImage src="../../images/podcasts/apple-logo.png" alt="apple"/></span></a>
                                            <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8zMGQxN2ZjNC9wb2RjYXN0L3Jzcw=="><span><StaticImage src="../../images/podcasts/google-logo.png" alt="google"/></span></a>
                                            <a href="https://www.breaker.audio/thin-space-1"><span><StaticImage src="../../images/podcasts/breaker-logo.png" alt="breaker"/></span></a>
                                            <a href="https://overcast.fm/itunes1527605777/thin-space"><span><StaticImage src="../../images/podcasts/overcast-logo.svg" alt="overcast"/></span></a>
                                            <a href="https://pca.st/p0uvvidi"><span><StaticImage src="../../images/podcasts/pocket-logo.svg" alt="pocket"/></span></a>
                                            <a href="https://radiopublic.com/thinspace-GKLVgz"><span><StaticImage src="../../images/podcasts/radio-logo.png" alt="radio casts"/></span></a>          
                                        </div>
                                    </div>
            
                            </div>
   
                                <div className={styles.photoContainer}>
                                   <PodcastImage/>
                                </div>
                          
                        </div>
                    </div>
                </div>    

            <PodcastArchive/>

            <div className={stylep.podcastBottomWrap}>
                <div className={stylep.podcastBottomLeft}>
                    <div className={stylep.podcastBottomContent}>
                        <h2>{data.sanitySiteSettings.podcastLeftHeader}</h2>
                        <p>{data.sanitySiteSettings.podcastLeftDescription}</p>
                        <Link to={data.sanitySiteSettings.podcastLeftLink}><span className={stylep.bottomBtn}>Learn More</span></Link>
                    </div>
                </div>

                <div className={stylep.podcastBottomRight}>
                    <div className={stylep.podcastBottomContent}>
                        <h2>{data.sanitySiteSettings.podcastRightHeader}</h2>
                        <p>{data.sanitySiteSettings.podcastRightDescription}</p>
                        
                        <div className={stylep.podcastLinksWrap}>
                            <a href="https://open.spotify.com/show/1uXLjKEj5ydJdQQ4uq6M3H"><span><StaticImage src="../../images/podcasts/spotify-logo.jpg" alt="Spotify"/></span></a>
                            <a href="https://podcasts.apple.com/us/podcast/thinspace/id1527605777?uo=4https://podcasts.apple.com/us/podcast/thinspace/id1527605777?uo=4"><span><StaticImage src="../../images/podcasts/apple-logo.png" alt="apple"/></span></a>
                            <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8zMGQxN2ZjNC9wb2RjYXN0L3Jzcw=="><span><StaticImage src="../../images/podcasts/google-logo.png" alt="google"/></span></a>
                            <a href="https://www.breaker.audio/thin-space-1"><span><StaticImage src="../../images/podcasts/breaker-logo.png" alt="breaker"/></span></a>
                            <a href="https://overcast.fm/itunes1527605777/thin-space"><span><StaticImage src="../../images/podcasts/overcast-logo.svg" alt="overcast"/></span></a>
                            <a href="https://pca.st/p0uvvidi"><span><StaticImage src="../../images/podcasts/pocket-logo.svg" alt="pocket"/></span></a>
                            <a href="https://radiopublic.com/thinspace-GKLVgz"><span><StaticImage src="../../images/podcasts/radio-logo.png" alt="radio casts"/></span></a>          
                        </div>
                    </div>
                </div>
               
            </div>
                    
              
        </section>
                    
             )}

        />
    </>)
}
