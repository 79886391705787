import React from 'react';
import * as styles from './PodcastArchive.module.scss'
import {StaticQuery, graphql} from 'gatsby'
import * as style from '../../styles/general.module.scss'
import {GatsbyImage} from 'gatsby-plugin-image';


export default function PodcastArchive() {
  var gridStyle = {marginBottom: "142px"}
  return (<div className={styles.podcastArchiveWrapper}>
      <StaticQuery query={graphql`
      query PodcastQuery {
        allSanityPodcast(sort: { fields: [date], order: DESC }) {
                              edges {
                                      node { 
                                              featuredCheck
                                              title
                                              description
                                              episodeNumber
                                              date
                                              runtime
                                              audioSrc
                                              image{
                                                      asset {
                                                          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
                                                          }
                                                      }
                                              }
                                        }
                                  }
                              }
                                
                              `} render={data => {
        var podcasts = [];
        var podcastsAfter = [];
        var latestPodcast = [];
        var featuredPodcasts = [];
        var counter = 0;
        var monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
          "Aug", "Sep", "Oct", "Nov", "Dec"];

        //inital loop of podcasts
        data.allSanityPodcast.edges.forEach(podcast => {
          //initial variables for every podcast
          var featured = podcast.node.featuredCheck;
          const date = podcast.node.date;
          var eventMonth = '';
          var eventDate = '';
          var eventYear = ''

          //podcast release month
          if (date.charAt(5) === '0') {
            eventMonth = date.charAt(6)
          } else {
            eventMonth = date.charAt(5) + date.charAt(6)
          }

          //podcast release date
          if (date.charAt(8) === '0') {
            eventDate = date.charAt(9)
          } else {
            eventDate = date.charAt(8) + date.charAt(9)
          }
          //podcast release year
          eventYear = date.charAt(0) + date.charAt(1) + date.charAt(2) + date.charAt(3)


          if (counter < 1) {
            latestPodcast.push(<div className={styles.latestPodcastCard}>
              <h3>Latest</h3>
              <h2> Ep {podcast.node.episodeNumber}: {podcast.node.title}</h2>
              <p>{podcast.node.description}</p>
              <div className={styles.audioWrap}>
                <audio controls controlsList="nodownload noplaybackrate" src={podcast.node.audioSrc}>
                  <track default kind="captions" srclang="en" src={podcast.node.audioSrc}/>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>

            </div>)
          } else if (counter > 0 && counter < 10) {
            if (featured === "yes") {
              featuredPodcasts.push(<div className={styles.featuredPodcastCard}>
                <div className={styles.featuredTop}>
                  <div>
                    <h4> Ep {podcast.node.episodeNumber}</h4>
                    <h5>{monthsShort[eventMonth - 1]} {eventDate}, {eventYear}</h5>
                    <h5>{podcast.node.runtime}</h5>
                  </div>
                  <div className={styles.podcastImage}>
                    {podcast.node.image &&
                      <div>
                        <GatsbyImage
                          alt={podcast.node.title}
                          image={podcast.node.image.asset.gatsbyImageData}>
                        </GatsbyImage>
                      </div>
                    }
                  </div>

                </div>
                <p>{podcast.node.description}</p>

                <audio controls controlsList="nodownload noplaybackrate" src={podcast.node.audioSrc}>
                  <track default kind="captions" srclang="en" src={podcast.node.audioSrc}/>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>)
            }
            podcasts.push(<div className={styles.podcastCard}>
              <div className={styles.featuredTop}>
                <div>
                  <h4> Ep {podcast.node.episodeNumber}</h4>
                  <h5>{monthsShort[eventMonth - 1]} {eventDate}, {eventYear}</h5>
                  <h5>{podcast.node.runtime}</h5>
                </div>
                <div className={styles.podcastImage}>
                  {podcast.node.image &&
                    <div>
                      <GatsbyImage
                        alt={podcast.node.title}
                        image={podcast.node.image.asset.gatsbyImageData}>
                      </GatsbyImage>
                    </div>
                  }
                </div>

              </div>
              <p>{podcast.node.description}</p>

              <audio controls controlsList="nodownload noplaybackrate" src={podcast.node.audioSrc}>
                <track default kind="captions" srclang="en" src={podcast.node.audioSrc}/>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </div>)

          } else {
            if (featured === "yes") {
              featuredPodcasts.push(<div className={styles.featuredPodcastCard}>
                <div className={styles.featuredTop}>
                  <div>
                    <h4> Ep {podcast.node.episodeNumber}</h4>
                    <h5>{monthsShort[eventMonth - 1]} {eventDate}, {eventYear}</h5>
                    <h5>{podcast.node.runtime}</h5>
                  </div>
                  <div className={styles.podcastImage}>
                    {podcast.node.image &&
                      <div>
                        <GatsbyImage
                          alt={podcast.node.title}
                          image={podcast.node.image.asset.gatsbyImageData}>
                        </GatsbyImage>
                      </div>
                    }
                  </div>

                </div>
                <p>{podcast.node.description}</p>

                <audio controls controlsList="nodownload noplaybackrate" src={podcast.node.audioSrc}>
                  <track default kind="captions" srclang="en" src={podcast.node.audioSrc}/>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>)
            }
            podcastsAfter.push(<div className={styles.podcastCard}>
              <div className={styles.featuredTop}>
                <div>
                  <h4> Ep {podcast.node.episodeNumber}</h4>
                  <h5>{monthsShort[eventMonth - 1]} {eventDate}, {eventYear}</h5>
                  <h5>{podcast.node.runtime}</h5>
                </div>
                <div className={styles.podcastImage}>
                  {podcast.node.image &&
                    <div>
                      <GatsbyImage
                        alt={podcast.node.title}
                        image={podcast.node.image.asset.gatsbyImageData}>
                      </GatsbyImage>
                    </div>
                  }
                </div>

              </div>
              <p>{podcast.node.description}</p>

              <audio controls controlsList="nodownload noplaybackrate" src={podcast.node.audioSrc}>
                <track default kind="captions" srclang="en" src={podcast.node.audioSrc}/>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </div>)

          }

          counter++;
        })


        return (<>
          <div className={styles.latestWrap}>
            <div className={style.layout}>
              {latestPodcast}
            </div>
          </div>


          <div className={style.layout}>
            <div className={styles.podcastMainGrid}>
              {podcasts}
            </div>
          </div>

          <div className={styles.featuredWrap}>
            <div className={style.layout}>
              <h2>New to the Podcast?</h2>
              <h3>Check out these featured episodes</h3>
              <div className={styles.featuredGrid}>
                {featuredPodcasts}
              </div>
            </div>
          </div>
          <div className={style.layout}>
            <div className={styles.podcastMainGrid} style={gridStyle}>
              {podcastsAfter}
            </div>
          </div>


        </>)

      }
      }/>
      {/* End of file */}

    </div>
  )
}
