import React from 'react'
import Layout from '../components/Layout'
import PodcastMain from '../components/PodcastMain/PodcastMain'

const PageTemplate = props => {

  return (
    <Layout>
        <PodcastMain/>
    </Layout>
  )
}

export default PageTemplate
