// extracted by mini-css-extract-plugin
export var audioWrap = "PodcastArchive-module--audio-wrap--153b0";
export var featuredGrid = "PodcastArchive-module--featured-grid--ab07c";
export var featuredPodcastCard = "PodcastArchive-module--featured-podcast-card--b837b";
export var featuredTop = "PodcastArchive-module--featured-top--0253b";
export var featuredWrap = "PodcastArchive-module--featured-wrap--ca2ed";
export var latestPodcastCard = "PodcastArchive-module--latest-podcast-card--22b26";
export var latestWrap = "PodcastArchive-module--latest-wrap--b8087";
export var podcastArchiveWrapper = "PodcastArchive-module--podcast-archive-wrapper--2cb31";
export var podcastCard = "PodcastArchive-module--podcast-card--2fb51";
export var podcastImage = "PodcastArchive-module--podcast-image--3b89f";
export var podcastMainGrid = "PodcastArchive-module--podcast-main-grid--95791";